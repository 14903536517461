import { useState } from "react";
import { tableType, tableResultType } from "../utils";
import { useGQL } from "../hooks";
import { useQuery, useSubscription } from "@apollo/react-hooks";

export const useTableHandler = (tableId: string | undefined) => {
  const [currentTable, setCurrentTable] = useState<null | tableType>(null);
  const [thisUserId, setThisUserId] = useState("");
  const {
    GET_TABLE,
    ON_PLAYER_STATUS_UPDATE,
    GET_ME,
    PLAYER_STATUS_ENUM,
  } = useGQL();

  useQuery(GET_ME, {
    onCompleted: (data) => {
      setThisUserId(data.me.id);
    },
  });

  const isPlayerSitting = () => {
    if (!thisUserId || !currentTable) {
      return false;
    } else {
      let foundPlayer = false;
      currentTable.playerStatus.forEach((playerStatus) => {
        if (
          playerStatus.userId === thisUserId &&
          (playerStatus.status === PLAYER_STATUS_ENUM.SITTING_IN ||
            playerStatus.status === PLAYER_STATUS_ENUM.SITTING_OUT)
        ) {
          foundPlayer = true;
        }
      });

      return foundPlayer;
    }
  };

  const { loading } = useQuery(GET_TABLE, {
    variables: {
      id: tableId,
    },
    onCompleted: (table: tableResultType) => {
      setCurrentTable(table.getTable);
    },
    onError: (error: any) => {
      console.log("error getting table: ", error);
    },
  });

  useSubscription(ON_PLAYER_STATUS_UPDATE, {
    variables: {
      tableId: tableId,
    },
    onSubscriptionData: (data: any) => {
      console.log("subscription updated with data", data);
      setCurrentTable(data.subscriptionData.data.subscribeToTableUpdates);
    },
  });

  const chipsInHand = (userId: string) => {
    let chips = 0;
    console.log(currentTable);
    if (currentTable) {
      currentTable.chipCounts.forEach((chipCount) => {
        if (chipCount.userId === userId) {
          chips = chipCount.chips;
        }
      });
    }
    return chips;
  };

  return {
    currentTable,
    loading,
    isPlayerSitting,
    chipsInHand,
    thisUserId,
  };
};
