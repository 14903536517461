import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { useGQL, useTableHandler, useHandHandler } from "../../hooks";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { PositionedSeat, TableControls, PokerCard } from "../../components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HandControls } from "./HandControls";

const Table = styled.div`
  background: green;
  height: 500px;
  min-width: 800px;
  width: 100%;
`;

export const Game = () => {
  const { UPDATE_PLAYER_TABLE_STATUS } = useGQL();
  const { tableId } = useParams();
  const {
    currentTable,
    loading,
    isPlayerSitting,
    chipsInHand,
    thisUserId,
  } = useTableHandler(tableId);

  const {
    isActiveUser,
    holdCards,
    chipsOnBoard,
    makeHandAction,
    chipsForHandInput,
    callAmount,
    tableCards,
  } = useHandHandler({
    currentTable,
    thisUserId,
    chipsInHand,
  });

  const onUpdateError = (error: any) => {
    console.log("error on update: ", error);
  };

  const [updatePlayerTableStatusMutation] = useMutation(
    UPDATE_PLAYER_TABLE_STATUS,
    {
      onError: onUpdateError,
      onCompleted: (tableResult: any) => {
        console.log("sat down? ", tableResult);
      },
    }
  );

  const updatePlayerStatus = async (status: string, position: number) => {
    try {
      console.log("trying to sit");
      if (currentTable && currentTable.groupId) {
        console.log("making the call");

        const variables = {
          groupId: currentTable.groupId,
          tableId: tableId,
          seatPosition: position,
          status: status,
        };
        console.log("variables: ", variables);
        await updatePlayerTableStatusMutation({
          variables,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPlayer = (index: number) => {
    if (currentTable) {
      return currentTable.playerStatus.filter(
        (playerStatus) => playerStatus.seatPosition === index
      )[0];
    } else {
      return null;
    }
  };

  const showCards = (index: number) => {
    if (getPlayer(index)?.userId === thisUserId) {
      return holdCards;
    } else {
      return null;
    }
  };

  const calculateTranslation = (index: number) => {
    if (currentTable) {
      const degreesToRotate =
        (index / currentTable.settings.numberOfSeats) * 2 * Math.PI;
      const xTranslation = 500 * Math.sin(degreesToRotate);
      const yTranslation = 250 * Math.cos(degreesToRotate);
      return `translateY(${yTranslation}px) translateX(${xTranslation}px)`;
    }
    return "";
  };

  return (
    <div>
      {loading && <p>Loading....</p>}
      <Row>
        <Col>
          <Table className="d-flex">
            {tableCards && (
              <div className="align-self-center align-items-center mx-auto d-flex">
                {tableCards().map((card, index) => (
                  <PokerCard card={card} key={index} />
                ))}
              </div>
            )}
            {currentTable &&
              currentTable.id &&
              [...Array(currentTable.settings.numberOfSeats)].map((x, i) => (
                <PositionedSeat
                  isAvailable={true}
                  key={i}
                  seatPosition={i}
                  updatePlayerStatus={updatePlayerStatus}
                  translation={calculateTranslation(i)}
                  player={getPlayer(i)}
                  isPlayerSitting={isPlayerSitting()}
                  chipsInHand={chipsInHand}
                  chipsOnBoard={chipsOnBoard}
                  playerCards={showCards(i)}
                  isActiveUser={isActiveUser}
                />
              ))}
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          {currentTable && (
            <TableControls
              groupId={currentTable.groupId}
              tableId={currentTable.id}
            />
          )}
        </Col>
        <Col></Col>
        <Col>
          <HandControls
            callAmount={callAmount()}
            chips={chipsForHandInput()}
            makeHandAction={makeHandAction}
          />
        </Col>
      </Row>
    </div>
  );
};
