import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useGQL } from "../hooks";
import Button from "react-bootstrap/Button";

type groupProps = {
  id: string;
  name: string;
};

export const GroupInvitations = () => {
  const { GET_USER_GROUPS_INVITATIONS, ACCEPT_GROUP_INVITATION } = useGQL();
  const { loading, data } = useQuery(GET_USER_GROUPS_INVITATIONS);

  const handleGroupAcceptance = () => {
    console.log("on accept");
  };

  const handleAcceptanceError = (error: any) => {
    console.log("in error: ", error);
  };

  const [acceptInvitationToGroup] = useMutation(ACCEPT_GROUP_INVITATION, {
    onCompleted: handleGroupAcceptance,
    onError: handleAcceptanceError,
  });

  const handleGroupAccept = async (groupId: string) => {
    console.log("the group id is: ", groupId);
    await acceptInvitationToGroup({
      variables: {
        groupId,
      },
    });
  };

  return (
    <div>
      <h1>Group invitations</h1>
      <ul>
        {data &&
          data.getGroupInvitations &&
          data.getGroupInvitations.map((group: groupProps) => (
            <li key={group.id} value={group.name}>
              <p>{group.name}</p>{" "}
              <Button onClick={() => handleGroupAccept(group.id)}>
                Accept
              </Button>
            </li>
          ))}
      </ul>
      {loading && <p>Loading</p>}
    </div>
  );
};
