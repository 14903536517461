import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation } from "@apollo/react-hooks";
import { useGQL } from "../hooks";
import { useParams, useHistory } from "react-router-dom";

export const CreateTable = () => {
  const [tableName, setTableName] = useState("");
  const [numberOfSeats, setNumberOfSeats] = useState(2);
  const [blindOptionsIndex, setBlindOptionsIndex] = useState(0);
  const { CREATE_TABLE } = useGQL();
  const { groupId } = useParams();
  const history = useHistory();

  const smallBlindOptions = [0.25, 0.5, 1, 2, 3, 5, 10, 25, 100];

  const blindOptions = smallBlindOptions.map((smallBlind) => {
    return { smallBlind: smallBlind, bigBlind: smallBlind * 2 };
  });

  console.log(blindOptions);

  const handleCreateTableError = (error: any) => {
    console.log(error);
  };

  const tableCreated = () => {
    history.push("/group/" + groupId);
  };

  const [createTable, { loading, error }] = useMutation(CREATE_TABLE, {
    onError: handleCreateTableError,
    onCompleted: tableCreated,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    console.log("numberOfSeats: ", numberOfSeats);
    await createTable({
      variables: {
        name: tableName,
        groupId: groupId,
        numberOfSeats: numberOfSeats,
        bigBlind: blindOptions[blindOptionsIndex].bigBlind,
        smallBlind: blindOptions[blindOptionsIndex].smallBlind,
      },
    });
  };
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Name of table</Form.Label>
          <Form.Control
            type="text"
            value={tableName}
            onChange={(event: any) => setTableName(event.target.value)}
            placeholder="Name of table"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Number of seats</Form.Label>
          <Form.Control
            value={numberOfSeats}
            onChange={(e) => setNumberOfSeats(parseInt(e.target.value))}
            as="select"
            required
          >
            {[...Array(10)].map((x, i) => (
              <option key={i + 2}>{i + 2}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Blinds</Form.Label>
          <Form.Control
            value={blindOptionsIndex}
            onChange={(e) => setBlindOptionsIndex(parseInt(e.target.value))}
            as="select"
          >
            {blindOptions.map((blinds, index) => (
              <option value={index} key={index}>
                {blinds.bigBlind}/{blinds.smallBlind}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button variant="outline-primary" disabled={loading} type="submit">
          {loading ? "Loading..." : "Create Table"}
        </Button>
      </Form>
      {error && <p>{error.message}</p>}
    </React.Fragment>
  );
};
