import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import config from "../config";
import { useAppState } from "../state";

const poolData = {
  UserPoolId: config.aws.userPoolId,
  ClientId: config.aws.clientId,
};

type signUpProps = {
  username: string;
  email: string;
  password: string;
};

export const useAuth = () => {
  const userPool = new CognitoUserPool(poolData);
  const { setUserIsAuthed } = useAppState();

  const updateUserAuth = (userAuth: boolean) => {
    if (setUserIsAuthed) {
      setUserIsAuthed(userAuth);
    }
  };

  const signUp = async ({ username, email, password }: signUpProps) => {
    await new Promise((resolve, reject) => {
      const emailAttribute = new CognitoUserAttribute({
        Name: "email",
        Value: email.toLowerCase(),
      });
      userPool.signUp(
        username,
        password,
        [emailAttribute],
        [],
        (err?, data?) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        }
      );
    });
  };

  const getUserJWT = async () => {
    return await new Promise<string>((resolve, reject) => {
      const user = userPool.getCurrentUser();
      if (user) {
        user.getSession((error?: Error, session?: CognitoUserSession) => {
          if (error) {
            console.log(error);
            reject(error);
          } else if (session) {
            resolve(session.getAccessToken().getJwtToken());
          } else {
            reject("Unknown error");
          }
        });
      } else {
        resolve("");
      }
    });
  };

  const checkForAuthedUser = () => {
    const user = userPool.getCurrentUser();
    if (user) {
      updateUserAuth(true);
    }
  };

  const signOut = () => {
    const user = userPool.getCurrentUser();
    if (user) {
      user.signOut();
      updateUserAuth(false);
    }
  };

  const signIn = async (username: string, password: string) => {
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username: username, Pool: userPool });
      const authDetails = new AuthenticationDetails({
        Username: username.toLowerCase(),
        Password: password,
      });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          updateUserAuth(true);
          resolve(data);
        },
        onFailure: (error) => {
          reject(error);
        },
        newPasswordRequired: (data) => {
          //Should never get here
          resolve(data);
        },
      });
    });
  };

  /*

data?.user.setDeviceStatusRemembered({
            onSuccess: function (result) {
              console.log("call result: " + result);
            },
            onFailure: function (err) {
              alert(err.message || JSON.stringify(err));
            },
          });
        }
*/

  return { signUp, signOut, signIn, getUserJWT, checkForAuthedUser };
};
