import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import config from "../config";
import { useAuth } from "../hooks";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";

type ContextProps = {
  userIsAuthed: boolean;
  setUserIsAuthed: Dispatch<SetStateAction<boolean>>;
};

export const AppContext = createContext<Partial<ContextProps>>({
  userIsAuthed: false,
});

type Props = {
  children: React.ReactNode;
};

export const PageWrapper = ({ children }: Props) => {
  const { getUserJWT } = useAuth();
  const [userIsAuthed, setUserIsAuthed] = useState(false);

  const url = config.aws.graphqlURL;
  const region = config.aws.region;
  const auth = {
    type: "AMAZON_COGNITO_USER_POOLS" as const,
    jwtToken: getUserJWT,
  };

  const httpLink = createHttpLink({ uri: url });

  const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink(url, httpLink),
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
  return (
    <AppContext.Provider value={{ userIsAuthed, setUserIsAuthed }}>
      <ApolloProvider client={client as any}>{children}</ApolloProvider>
    </AppContext.Provider>
  );
};

export const useAppState = () => {
  return useContext(AppContext);
};
