import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { useGQL } from "../../hooks";
import { useMutation } from "@apollo/react-hooks";

type TableControlsType = {
  groupId: String;
  tableId: String;
};

export const TableControls = ({ groupId, tableId }: TableControlsType) => {
  const [chipsToAdd, setChipsToAdd] = useState("");
  const { ADD_CHIPS } = useGQL();

  const [addChipsMutation] = useMutation(ADD_CHIPS, {
    onCompleted: (data: any) => {
      console.log(data);
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  const addChips = () => {
    addChipsMutation({
      variables: {
        numberOfChips: parseInt(chipsToAdd),
        groupId: groupId,
        tableId: tableId,
      },
    });
  };

  const handleAddChipsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChipsToAdd(event.target.value);
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h4 className="text-center">Table controls</h4>
        </Col>
      </Row>
      <Form>
        <Row>
          <Col>
            <Accordion>
              <Accordion.Toggle as={Button} eventKey="0">
                Add Chips
              </Accordion.Toggle>
              <Form.Check
                className="ml-3"
                inline
                label="Stand"
                type="checkbox"
                id="stand-checkbox"
              />
              <Form.Check
                inline
                label="Sit out"
                type="checkbox"
                id="sit-out-checkbox"
              />
              <Accordion.Collapse className="mt-1" eventKey="0">
                <InputGroup>
                  <Form.Control
                    type="Text"
                    aria-label="Add chips"
                    value={chipsToAdd}
                    onChange={handleAddChipsChange}
                  />
                  <InputGroup.Append>
                    <Accordion.Toggle
                      as={Button}
                      onClick={addChips}
                      eventKey="0"
                    >
                      Add
                    </Accordion.Toggle>
                    <Accordion.Toggle
                      variant="secondary"
                      as={Button}
                      eventKey="0"
                    >
                      Cancel
                    </Accordion.Toggle>
                  </InputGroup.Append>
                </InputGroup>
              </Accordion.Collapse>
            </Accordion>
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};
