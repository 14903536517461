import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAppState } from "../../state";

type Props = {
  requiresAuth: boolean;
  children: React.ReactNode;
};

export const AuthRedirect = ({ children, requiresAuth }: Props) => {
  const { userIsAuthed } = useAppState();
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <React.Fragment>
      {hasMounted && requiresAuth && !userIsAuthed && (
        <Redirect to={{ pathname: "/sign-in" }} />
      )}
      {hasMounted && !requiresAuth && userIsAuthed && (
        <Redirect to={{ pathname: "/" }} />
      )}
      {requiresAuth === userIsAuthed && children}
    </React.Fragment>
  );
};
