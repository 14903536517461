import React from "react";
import Button from "react-bootstrap/Button";

type EmptySeatProps = {
  className?: string;
  handleSit: (event: React.MouseEvent<HTMLElement>) => void;
};

export const EmptySeat = ({ className, handleSit }: EmptySeatProps) => {
  return (
    <div className={className}>
      <p>{"Seat"}</p>
      <Button onClick={handleSit}>Sit</Button>
    </div>
  );
};
