import React from "react";
import "./App.scss";
import { NavBar, MainContent } from "./components";
import { BrowserRouter as Router } from "react-router-dom";
import { PageWrapper } from "./state";
import Container from "react-bootstrap/Container";

function App() {
  return (
    <PageWrapper>
      <Router>
        <NavBar />
        <Container>
          <MainContent />
        </Container>
      </Router>
    </PageWrapper>
  );
}

export default App;
