import React from "react";
import { ReactSVG } from "react-svg";

type PokerCardType = {
  card: string;
};

export const PokerCard = ({ card }: PokerCardType) => {
  let filename = "card-";
  switch (card[1]) {
    case "h":
      filename += "heart";
      break;
    case "s":
      filename += "spade";
      break;
    case "d":
      filename += "diamond";
      break;
    case "c":
      filename += "club";
      break;
    default:
      console.log("unknown suit on card");
      break;
  }

  let cardNumber = card[0];

  switch (cardNumber) {
    case "A":
      cardNumber = "1";
      break;
    case "J":
      cardNumber = "11";
      break;
    case "Q":
      cardNumber = "12";
      break;
    case "K":
      cardNumber = "13";
      break;
    default:
      break;
  }

  filename += `-${cardNumber}.svg`;

  return (
    <div className="mx-2">
      <ReactSVG src={require(`../../img/cards/${filename}`)} />
    </div>
  );
};
