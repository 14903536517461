import { useState, useEffect } from "react";
import { tableType, handType, holdCardsType } from "../utils";
import { useGQL } from "../hooks";
import {
  useMutation,
  useLazyQuery,
  useSubscription,
} from "@apollo/react-hooks";

type useHandHandlerType = {
  currentTable: tableType | null;
  thisUserId: string | null;
  chipsInHand: (userId: string) => number;
};

export const useHandHandler = ({
  currentTable,
  thisUserId,
  chipsInHand,
}: useHandHandlerType) => {
  const [currentHand, setCurrentHand] = useState<null | handType>(null);
  const [holdCards, setHoldCards] = useState<null | holdCardsType>(null);
  const [isQueryingForHand, setIsQueryingForHand] = useState(false);
  const {
    CREATE_NEW_HAND,
    GET_MY_HOLD_CARDS,
    HAND_ACTION,
    ON_HAND_UPDATE,
  } = useGQL();
  const [getHoldCards] = useLazyQuery(GET_MY_HOLD_CARDS, {
    onCompleted: (data: any) => {
      setHoldCards(data.getMyHoldCards);
    },
    onError: (error: any) => {
      console.log("error getting hold cards: ", error);
    },
  });

  const [handAction] = useMutation(HAND_ACTION, {
    onCompleted: (data: any) => {
      console.log("hand action complete with: ", data);
    },
    onError: (error: any) => {
      console.log("hand action error: ", error);
    },
  });

  const isActiveUser = (userId: string) => {
    if (currentHand && userId === currentHand.nextToAct) {
      return true;
    } else {
      return false;
    }
  };

  useSubscription(ON_HAND_UPDATE, {
    variables: {
      tableId: currentTable?.id,
    },
    onSubscriptionData: (data: any) => {
      console.log("subscription updated hand with data", data);
      setCurrentHand(data.subscriptionData.data.subscribeToHandUpdates);
    },
  });

  const makeHandAction = (action: string, amount: number) => {
    if (currentTable && currentHand) {
      console.log(
        `calling make hand with tableId: ${currentTable.id} handId:${currentHand.id} groupId:${currentTable.groupId} action${action} and amount ${amount}`
      );
      handAction({
        variables: {
          tableId: currentTable.id,
          groupId: currentTable.groupId,
          handId: currentHand.id,
          amount: amount,
          action: action,
        },
      });
    }
  };

  const chipsOnBoard = (userId: string) => {
    if (currentHand) {
      const playerPot = currentHand.chips.playerPots.filter(
        (pot) => pot.userId === userId
      );
      if (playerPot[0]) {
        return playerPot[0].amount;
      }
    }
    return 0;
  };

  const chipsForHandInput = () => {
    let chipsForInput = {
      pot: 0,
      chipsInPot: 0,
      totalChips: 0,
    };

    if (thisUserId) {
      chipsForInput.chipsInPot = chipsOnBoard(thisUserId);
      chipsForInput.totalChips = chipsInHand(thisUserId);
    }

    return chipsForInput;
  };

  const callAmount = () => {
    if (currentHand) {
      return currentHand.callAmount;
    }
    return 0;
  };

  useEffect(() => {
    const getCardsIfHand = () => {
      if (currentHand && !holdCards) {
        const handId = currentHand.id;
        const tableId = currentHand.tableId;
        getHoldCards({
          variables: {
            handId,
            tableId,
          },
        });
        console.log("we in it");
      }
    };
    getCardsIfHand();
  }, [currentHand, getHoldCards, holdCards]);

  const [createNewHand] = useMutation(CREATE_NEW_HAND, {
    onError: (error: any) => {
      console.log("error creating hand: ", error);
    },
    onCompleted: (data: any) => {
      console.log("completed hand with: ", data);
    },
  });

  const tableCards = () => {
    if (currentHand) {
      switch (currentHand.stage) {
        case "PRE_FLOP":
          return [];
        case "FLOP":
          return currentHand.tableCards.slice(0, 3);
        case "TURN":
          return currentHand.tableCards.slice(0, 4);
        case "RIVER":
          return currentHand.tableCards.slice(0, 5);
        default:
          return currentHand.tableCards;
      }
    }
    return [];
  };

  useEffect(() => {
    const evaluateIfHandNeedsToBeCreated = () => {
      if (!currentHand || currentHand.status.includes("COMPLETE")) {
        if (!isQueryingForHand) {
          if (currentTable && currentTable.currentHand) {
            //Get current hand to see if it's complete
            if (currentTable.currentHand.status.includes("COMPLETE")) {
            } else {
              setCurrentHand(currentTable.currentHand);
            }
            console.log("currentTable: ", currentTable);
          } else if (currentTable) {
            setIsQueryingForHand(true);
            createNewHand({
              variables: {
                tableId: currentTable.id,
                groupId: currentTable.groupId,
              },
            });
            console.log("in else currentTable: ", currentTable);
          }
        }
      }
    };
    evaluateIfHandNeedsToBeCreated();
  }, [createNewHand, currentHand, currentTable, isQueryingForHand]);

  return {
    currentHand,
    isActiveUser,
    holdCards,
    chipsOnBoard,
    makeHandAction,
    chipsForHandInput,
    callAmount,
    tableCards,
  };
};
