import gql from "graphql-tag";

export const useGQL = () => {
  const GET_USER_FROM_USERNAME = gql`
    query GetUserFromUsername($username: String!) {
      getUserFromUsername(username: $username) {
        userId
        id
        displayName
      }
    }
  `;

  const CREATE_GROUP = gql`
    mutation CreateGroup($name: String!) {
      createGroup(group: { name: $name }) {
        id
      }
    }
  `;

  const CREATE_USER_PROFILE = gql`
    mutation CreateUserProfile($displayName: String!) {
      createUserProfile(user: { displayName: $displayName }) {
        id
      }
    }
  `;

  const INVITE_USER_TO_GROUP = gql`
    mutation InviteUserToGroup($groupId: String!, $invitedUserId: String!) {
      inviteUserToGroup(
        invitation: { groupId: $groupId, invitedUserId: $invitedUserId }
      ) {
        id
        name
      }
    }
  `;

  const ACCEPT_GROUP_INVITATION = gql`
    mutation AcceptGroupInvitation($groupId: String!) {
      acceptGroupInvitation(groupId: $groupId) {
        id
        name
      }
    }
  `;

  const GET_USER_GROUPS = gql`
    {
      getUserGroups {
        id
        name
      }
    }
  `;

  const GET_ME = gql`
    {
      me {
        id
        displayName
        userId
      }
    }
  `;

  const GET_MY_HOLD_CARDS = gql`
    query GetMyHoldCards($tableId: ID!, $handId: ID!) {
      getMyHoldCards(handId: $handId, tableId: $tableId) {
        firstCard
        secondCard
      }
    }
  `;

  const GET_USER_GROUPS_INVITATIONS = gql`
    {
      getGroupInvitations {
        id
        name
      }
    }
  `;

  const CREATE_TABLE = gql`
    mutation CreateTable(
      $name: String!
      $groupId: String!
      $numberOfSeats: Int!
      $bigBlind: Float!
      $smallBlind: Float!
    ) {
      createTable(
        table: {
          name: $name
          groupId: $groupId
          settings: {
            numberOfSeats: $numberOfSeats
            bigBlind: $bigBlind
            smallBlind: $smallBlind
          }
        }
      ) {
        id
      }
    }
  `;

  const HAND_ATTRIBUTES = gql`
    fragment handAttributes on Hand {
      id
      tableId
      status
      nextToAct
      callAmount
      stage
      tableCards
      chips {
        mainPot {
          amount
        }
        playerPots {
          userId
          amount
        }
      }
    }
  `;

  const TABLE_ATTRIBUTES = gql`
    fragment tableAttributes on Table {
      id
      name
      settings {
        numberOfSeats
      }
      playerStatus {
        userId
        seatPosition
        status
        displayName
      }
      chipCounts {
        userId
        chips
      }
      groupId
      currentHand {
        ...handAttributes
      }
    }
    ${HAND_ATTRIBUTES}
  `;

  const GET_TABLE = gql`
    query GetTable($id: ID!) {
      getTable(id: $id) {
        ...tableAttributes
      }
    }
    ${TABLE_ATTRIBUTES}
  `;

  const ADD_CHIPS = gql`
    mutation AddChips($tableId: ID!, $groupId: ID!, $numberOfChips: Int!) {
      addChips(
        tableId: $tableId
        groupId: $groupId
        numberOfChips: $numberOfChips
      ) {
        ...tableAttributes
      }
    }
    ${TABLE_ATTRIBUTES}
  `;

  const UPDATE_PLAYER_TABLE_STATUS = gql`
    mutation UpdatePlayerTableStatus(
      $tableId: ID!
      $groupId: ID!
      $status: PlayerTableStatusEnum!
      $seatPosition: Int
    ) {
      updatePlayerTableStatus(
        tableId: $tableId
        status: {
          groupId: $groupId
          status: $status
          seatPosition: $seatPosition
        }
      ) {
        ...tableAttributes
      }
    }
    ${TABLE_ATTRIBUTES}
  `;

  const ON_PLAYER_STATUS_UPDATE = gql`
    subscription SubscribeToTableUpdates($tableId: String!) {
      subscribeToTableUpdates(id: $tableId) {
        id
      }
    }
  `;

  const ON_HAND_UPDATE = gql`
    subscription SubscribeToHandUpdates($tableId: String!) {
      subscribeToHandUpdates(tableId: $tableId) {
        ...handAttributes
      }
    }
    ${HAND_ATTRIBUTES}
  `;

  const HAND_ACTION = gql`
    mutation HandAction(
      $tableId: ID!
      $groupId: ID!
      $handId: ID!
      $amount: Float
      $action: HandActionEnum!
    ) {
      handAction(
        tableId: $tableId
        groupId: $groupId
        handId: $handId
        amount: $amount
        action: $action
      ) {
        ...handAttributes
      }
    }
    ${HAND_ATTRIBUTES}
  `;

  const CREATE_NEW_HAND = gql`
    mutation CreateNewHand($tableId: ID!, $groupId: ID!) {
      createNewHand(tableId: $tableId, groupId: $groupId) {
        ...handAttributes
      }
    }
    ${HAND_ATTRIBUTES}
  `;

  const PLAYER_STATUS_ENUM = {
    SITTING_IN: "SITTING_IN",
    SITTING_OUT: "SITTING_OUT",
    STANDING: "STANDING",
    LEFT: "LEFT,",
  };

  const HAND_ACTION_ENUM = {
    CHECK: "CHECK",
    CALL: "CALL",
    RAISE: "RAISE",
    FOLD: "FOLD",
    ALL_IN: "ALL_IN",
  };

  return {
    GET_USER_FROM_USERNAME,
    CREATE_GROUP,
    INVITE_USER_TO_GROUP,
    GET_USER_GROUPS,
    GET_USER_GROUPS_INVITATIONS,
    CREATE_TABLE,
    CREATE_USER_PROFILE,
    ACCEPT_GROUP_INVITATION,
    GET_TABLE,
    PLAYER_STATUS_ENUM,
    UPDATE_PLAYER_TABLE_STATUS,
    ON_PLAYER_STATUS_UPDATE,
    GET_ME,
    ADD_CHIPS,
    CREATE_NEW_HAND,
    GET_MY_HOLD_CARDS,
    HAND_ACTION,
    HAND_ACTION_ENUM,
    ON_HAND_UPDATE,
  };
};
