import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { useGQL } from "../hooks";

export const CreateGroup = () => {
  const [groupName, setGroupName] = useState("");
  const { CREATE_GROUP } = useGQL();
  const [createGroup] = useMutation(CREATE_GROUP);
  const history = useHistory();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await createGroup({
        variables: {
          name: groupName,
        },
      });

      history.push("/groups");
    } catch (error) {
      //TODO Add Error
      console.log(error);
    }
  };

  return (
    <div>
      <h2>Create a group</h2>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          value={groupName}
          onChange={(event) => setGroupName(event.target.value)}
          placeholder="Name of group"
          required
        />
        <button type="submit">Create</button>
      </form>
    </div>
  );
};
