import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useAppState } from "../state";
import { useAuth } from "../hooks";

export const NavBar = () => {
  const { userIsAuthed } = useAppState();
  const { checkForAuthedUser, signOut } = useAuth();
  const history = useHistory();

  const signUserOut = () => {
    signOut();
    history.push("/");
  };

  useEffect(() => {
    checkForAuthedUser();
  }, [checkForAuthedUser]);

  return (
    <Navbar>
      <Nav.Item>
        <Nav.Link as={Link} to="/">
          Home
        </Nav.Link>
      </Nav.Item>
      {!userIsAuthed && (
        <React.Fragment>
          <Nav.Item>
            <Nav.Link as={Link} to="/sign-up">
              Sign Up
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/sign-in">
              Sign In
            </Nav.Link>
          </Nav.Item>
        </React.Fragment>
      )}
      {userIsAuthed && (
        <React.Fragment>
          <Nav.Item>
            <Nav.Link as={Link} to="/groups">
              Groups
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="ml-auto">
            <Nav.Link onClick={signUserOut}>Sign out</Nav.Link>
          </Nav.Item>
        </React.Fragment>
      )}
    </Navbar>
  );
};
