import React from "react";
import { useParams, Link, useRouteMatch } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Button from "react-bootstrap/Button";

const GET_GROUP_TABLES = gql`
  query GetGroupTables($groupId: String!) {
    getGroupTables(groupId: $groupId) {
      id
      name
    }
  }
`;

type tableProps = {
  id: string;
  name: string;
};

export const Tables = () => {
  let { url } = useRouteMatch();
  const { groupId } = useParams();
  const { loading, data } = useQuery(GET_GROUP_TABLES, {
    variables: { groupId: groupId },
  });

  //TODO Handle error

  return (
    <div>
      Your tables
      <ul>
        {data &&
          data.getGroupTables &&
          data.getGroupTables.map((table: tableProps) => (
            <li key={table.id} value={table.name}>
              <Link to={`/table/${table.id.replace("TABLE#", "")}/game`}>
                {table.name}
              </Link>
            </li>
          ))}
      </ul>
      {loading && <p>Loading</p>}
      <Link to={`${url}/create-table`}>
        <Button>Create Table</Button>
      </Link>
    </div>
  );
};
