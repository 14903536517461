import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { EmailPasswordForm } from "./EmailPasswordForm";
import { useAuth } from "../../hooks";

export const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { signIn } = useAuth();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    setLoading(true);
    try {
      await signIn(username, password);
      setLoading(false);
      history.push("/groups");
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message);
    }
  };
  return (
    <div>
      <EmailPasswordForm
        username={username}
        password={password}
        onSubmit={onSubmit}
        setUsername={setUsername}
        setPassword={setPassword}
        buttonLabel="Sign in"
        loading={loading}
        errorMessage={errorMessage}
      />
    </div>
  );
};
