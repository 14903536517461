import React from "react";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { GroupInvitations } from "../components";

const GET_USER_GROUPS = gql`
  {
    getUserGroups {
      id
      name
    }
  }
`;

type groupProps = {
  id: string;
  name: string;
};

export const Groups = () => {
  const { loading, data } = useQuery(GET_USER_GROUPS);
  //TODO: Add error handling
  return (
    <div>
      <p>Groups</p>
      <Link to="/create-group">
        <button>Create Group</button>
      </Link>
      <ul>
        {data &&
          data.getUserGroups &&
          data.getUserGroups.map((group: groupProps) => (
            <li key={group.id} value={group.name}>
              <Link to={`/group/${group.id.replace("GROUP#", "")}`}>
                {group.name}
              </Link>
            </li>
          ))}
      </ul>
      {loading && <p>Loading</p>}
      <GroupInvitations />
    </div>
  );
};
