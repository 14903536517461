import React from "react";
import styled from "styled-components";
import { useGQL } from "../../hooks";
import { playerStatusType, holdCardsType } from "../../utils";
import { EmptySeat, PokerCard } from "../../components";
import ProgressBar from "react-bootstrap/ProgressBar";

type PlayerSeatProps = {
  className?: string;
  isAvailable: boolean;
  translation: string;
  seatPosition: number;
  updatePlayerStatus: Function;
  player: playerStatusType | null;
  isPlayerSitting: boolean;
  chipsInHand: (userId: string) => number;
  chipsOnBoard: (userId: string) => number | undefined;
  playerCards: holdCardsType | null;
  isActiveUser: (userId: string) => boolean;
};

export const PlayerSeat = ({
  className,
  seatPosition,
  updatePlayerStatus,
  player,
  isPlayerSitting,
  chipsInHand,
  chipsOnBoard,
  playerCards,
  isActiveUser,
  translation,
}: PlayerSeatProps) => {
  const { PLAYER_STATUS_ENUM } = useGQL();

  const handleSit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    updatePlayerStatus(PLAYER_STATUS_ENUM.SITTING_IN, seatPosition);
  };

  console.log(translation);

  if (isPlayerSitting && !player) {
    return null;
  }

  if (!player) {
    return <EmptySeat handleSit={handleSit} className={className} />;
  }

  return (
    <div className={className}>
      <div className="d-flex flex-column">
        <div>
          <div className="d-flex order-1">
            {playerCards && (
              <>
                <PokerCard card={playerCards.firstCard} />
                <PokerCard card={playerCards.secondCard} />
              </>
            )}
          </div>
          <p className="order-2">{player.displayName}</p>
          <p className="order-3">Chips: {chipsInHand(player.userId)}</p>
          {isActiveUser(player.userId) && (
            <ProgressBar striped variant="info" animated now={50} />
          )}
        </div>
        <p className="order-0">{chipsOnBoard(player.userId)}</p>
      </div>
    </div>
  );
};

export const PositionedSeat = styled(PlayerSeat)<PlayerSeatProps>`
  height: 100px;
  width: 100px;
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  transform: ${(props) => props.translation};
  z-index: 100;
`;
