import React from "react";
import {
  SignUp,
  Home,
  SignIn,
  PrivacyPolicy,
  NotFound,
  TermsOfService,
  Groups,
  CreateGroup,
  Tables,
  Game,
  AuthRedirect,
  Members,
  InviteMember,
  CreateTable,
} from "../components";
import { Switch, Route } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export const MainContent = () => {
  return (
    <Switch>
      <Route exact path="/sign-up">
        <AuthRedirect requiresAuth={false}>
          <SignUp />
        </AuthRedirect>
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/sign-in">
        <AuthRedirect requiresAuth={false}>
          <SignIn />
        </AuthRedirect>
      </Route>
      <Route exact path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/terms-of-service">
        <TermsOfService />
      </Route>
      <Route exact path="/groups">
        <AuthRedirect requiresAuth={true}>
          <Groups />
        </AuthRedirect>
      </Route>
      <Route exact path="/create-group">
        <AuthRedirect requiresAuth={true}>
          <CreateGroup />
        </AuthRedirect>
      </Route>
      <Route exact path="/group/:groupId">
        <AuthRedirect requiresAuth={true}>
          <Row>
            <Col>
              <Tables />
            </Col>
            <Col>
              <Members />
            </Col>
          </Row>
        </AuthRedirect>
      </Route>
      <Route path="/group/:groupId/invite-user">
        <AuthRedirect requiresAuth={true}>
          <InviteMember />
        </AuthRedirect>
      </Route>
      <Route path="/group/:groupId/create-table">
        <AuthRedirect requiresAuth={true}>
          <CreateTable />
        </AuthRedirect>
      </Route>
      <Route path="/table/:tableId/game">
        <AuthRedirect requiresAuth={true}>
          <Game />
        </AuthRedirect>
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};
