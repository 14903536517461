import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useGQL } from "../../hooks";

type HandControlsType = {
  makeHandAction: (action: string, amount: number) => void;
  callAmount: number;
  chips: { pot: number; chipsInPot: number; totalChips: number };
};

export const HandControls = ({
  makeHandAction,
  callAmount,
  chips,
}: HandControlsType) => {
  const { HAND_ACTION_ENUM } = useGQL();
  const [betAmount, setBetAmount] = useState(0);

  const handleAction = (action: string) => {
    console.log(action);
    let amount = 0;
    if (action === HAND_ACTION_ENUM.RAISE) {
    }
    if (action === HAND_ACTION_ENUM.CALL) {
      amount = amountToCall();
    }
    if (action === HAND_ACTION_ENUM.ALL_IN) {
    }
    makeHandAction(action, amount);
  };

  const handleRaiseButton = (amount: number) => {
    let action = HAND_ACTION_ENUM.RAISE;
    if (amount === 1000) {
      action = HAND_ACTION_ENUM.ALL_IN;
    }
    makeHandAction(action, amount);
  };

  const canCheck = () => {
    return chips.chipsInPot === callAmount;
  };

  const amountToCall = () => {
    return callAmount - chips.chipsInPot;
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h4 className="text-center">Hand controls</h4>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Button variant="dark" block size="sm">
            Min
          </Button>
        </Col>
        <Col>
          <Button variant="dark" block size="sm">
            1/2 pot
          </Button>
        </Col>
        <Col>
          <Button variant="dark" block size="sm">
            Pot
          </Button>
        </Col>
        <Col>
          <Button variant="dark" block size="sm">
            All In
          </Button>
        </Col>
      </Row>
      <Form>
        <Row className="align-items-center mb-2 no-gutters">
          <Col xs={3} className="pr-1">
            <Form.Control
              type="input"
              value={betAmount}
              onChange={(e) => setBetAmount(parseFloat(e.target.value))}
            />
          </Col>
          <Col>
            <Form.Control type="range" />
          </Col>
        </Row>
      </Form>
      <Row className="mb-2">
        {canCheck() && (
          <Col>
            <Button
              variant="dark"
              onClick={() => handleAction(HAND_ACTION_ENUM.CHECK)}
              block
              size="lg"
            >
              Check
            </Button>
          </Col>
        )}
        {!canCheck() && (
          <>
            <Col>
              <Button
                variant="dark"
                onClick={() => handleAction(HAND_ACTION_ENUM.CALL)}
                block
                size="lg"
              >
                Call {amountToCall()}
              </Button>
            </Col>
            <Col>
              <Button
                variant="dark"
                onClick={() => handleAction(HAND_ACTION_ENUM.FOLD)}
                block
                size="lg"
              >
                Fold
              </Button>
            </Col>
          </>
        )}
        <Col>
          <Button
            variant="dark"
            onClick={() => handleAction(HAND_ACTION_ENUM.RAISE)}
            block
            size="lg"
          >
            Raise
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};
