import React, { useState } from "react";
import { useAuth } from "../../hooks";
import { useHistory } from "react-router-dom";
import { EmailPasswordForm } from "./EmailPasswordForm";
import { useGQL } from "../../hooks";
import { useMutation } from "@apollo/react-hooks";

export const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const { signUp, signIn } = useAuth();
  const { CREATE_USER_PROFILE } = useGQL();
  const [createUserProfile] = useMutation(CREATE_USER_PROFILE);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");
    try {
      await signUp({ username, email, password });
      await signIn(username, password);
      await createUserProfile({
        variables: {
          displayName: username,
        },
      });
      setLoading(false);
      //TODO Make profile
      history.push("/groups");
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <EmailPasswordForm
        username={username}
        setUsername={setUsername}
        email={email}
        password={password}
        onSubmit={onSubmit}
        setEmail={setEmail}
        setPassword={setPassword}
        buttonLabel="Sign up"
        loading={loading}
        errorMessage={errorMessage}
      />
    </div>
  );
};
