import React, { Dispatch, SetStateAction } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

type Props = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  username: string;
  email?: string;
  password: string;
  setUsername: Dispatch<SetStateAction<string>>;
  setEmail?: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  buttonLabel: string;
  loading: boolean;
  errorMessage: string;
};

export const EmailPasswordForm = ({
  onSubmit,
  email,
  password,
  setEmail,
  setPassword,
  buttonLabel,
  username,
  setUsername,
  loading,
  errorMessage,
}: Props) => {
  return (
    <React.Fragment>
      <Row md={2} className="justify-content-center">
        <Col>
          <h1>{buttonLabel}</h1>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                placeholder="username"
                required
              />
            </Form.Group>
            {setEmail && (
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="email"
                  required
                />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="password"
                required
              />
            </Form.Group>
            {/* <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Remember Me" />
            </Form.Group> */}
            <Button variant="outline-primary" disabled={loading} type="submit">
              {loading ? "Loading..." : buttonLabel}
            </Button>
          </Form>
        </Col>
      </Row>
      <Row md={3} className="mt-2">
        <Col>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        </Col>
      </Row>
    </React.Fragment>
  );
};
