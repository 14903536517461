import React from "react";
import Button from "react-bootstrap/Button";
import { useRouteMatch, Link } from "react-router-dom";

export const Members = () => {
  let { url } = useRouteMatch();
  return (
    <React.Fragment>
      <Link to={`${url}/invite-user`}>
        <Button>Invite user</Button>
      </Link>
    </React.Fragment>
  );
};
