// cSpell:ignore afqjt,llir,ulti,pjjh,dfdo,gkxhfsfn,dgvrfqm
const config = {
  aws: {
    userPoolId: "us-east-1_2sdXNp4vJ",
    clientId: "1afqjt9jr9llir6ulti4l0pjjh",
    graphqlURL: "https://api.housepokergame.com/graphql",
    graphqlRealtimeURL:
      "wss://ve2dfdo4r5gkxhfsfn4dgvrfqm.appsync-realtime-api.us-east-1.amazonaws.com/graphql",
    region: "us-east-1",
  },
};

export default config;
