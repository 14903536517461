import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useGQL } from "../hooks";
import { useParams } from "react-router-dom";

export const InviteMember = () => {
  const [username, setUsername] = useState("");
  const [skipUserQuery, setSkipUserQuery] = useState(true);
  const { GET_USER_FROM_USERNAME, INVITE_USER_TO_GROUP } = useGQL();
  const { groupId } = useParams();

  const fetchedUser = () => {
    if (data) {
      setSkipUserQuery(true);
      if (data.getUserFromUsername.length === 1) {
        inviteUser({
          variables: {
            invitedUserId: data.getUserFromUsername[0].userId,
            groupId: groupId,
          },
        });
      }
    }
  };

  const invitedUser = () => {
    console.log(invitedData);
    console.log("invitedUser");
  };

  const errorOnUserInvite = () => {};

  const [
    inviteUser,
    { loading: invitedLoading, data: invitedData, error: invitedError },
  ] = useMutation(INVITE_USER_TO_GROUP, {
    onCompleted: invitedUser,
    onError: errorOnUserInvite,
  });

  const { loading, data, error } = useQuery(GET_USER_FROM_USERNAME, {
    variables: {
      username: username.toLowerCase(),
    },
    skip: skipUserQuery,
    onCompleted: fetchedUser,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSkipUserQuery(false);

    console.log(data);
    console.log(error);
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Username to invite</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            placeholder="username"
            required
          />
        </Form.Group>
        <Button variant="outline-primary" disabled={loading} type="submit">
          {loading || invitedLoading ? "Loading..." : "Invite"}
        </Button>
      </Form>

      {error && <p>{error.message}</p>}
      {invitedError && <p>{invitedError.message}</p>}
    </React.Fragment>
  );
};
